import React from "react";
import loadable from "@loadable/component";
import useWindowDimensions from "@hooks/useWindowDimensions";
import {
  IMPRESSIT_OFFERS_DESCRIPTION,
  IMPRESSIT_OFFERS_HEADING,
  IMPRESSIT_OFFER_ITEMS,
} from "../../constants";
import { StyledOfferSectionWrapper } from "./styled";

const DropDownListSection = loadable(() => import("@common/CommonSections/DropDownListSection/DropDownListSection"));

const OffersSection = () => {
  const { isMobile } = useWindowDimensions();

  return (
    <StyledOfferSectionWrapper>
      <DropDownListSection
        sectionItems={IMPRESSIT_OFFER_ITEMS}
        isMobile={isMobile}
        heading={IMPRESSIT_OFFERS_HEADING}
        description={IMPRESSIT_OFFERS_DESCRIPTION}
        headingMargin="40px"
        itemsMargin="50px"
      />
    </StyledOfferSectionWrapper>
  )
}

export default OffersSection
