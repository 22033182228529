import { TABLET_MAX_WIDTH, MOBILE_MIN_WIDTH } from "@constants";
import styled from "styled-components";

export const OfferSectionContainer = styled.div`
    margin-bottom: 10rem;

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        margin-bottom: 6.75rem;
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        margin-bottom: 5rem;
    }

    & .development-section {
        padding-bottom: 0;

        & h2 {
            font-weight: 700;
            font-size: 2rem;
            line-height: 2.4375rem;
            letter-spacing: 0.02em;
            color: rgba(255, 255, 255, 0.8);

            @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
                font-size: 1.75rem;
                line-height: 2.25rem;
                width: 90%;
            }

            @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
                font-size: 1.25rem;
                line-height: 1.875rem;
            }
        }
    }

    & .product-development-item {
        grid-template-columns: 1fr 4fr;

        @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
            grid-template-columns: 0fr 4fr;
            column-gap: 0;
        }
    }

    & .page-heading {
        text-align: center;
    }
`;
