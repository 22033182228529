import React from "react";
import loadable from "@loadable/component";
import useWindowDimensions from "@hooks/useWindowDimensions";
import {
  IMPRESSIT_OFFERS_HEADING,
  IMPRESSIT_OFFER_ITEMS,
} from "../../constants";
import { OfferSectionContainer } from "./OffersSection.style";

const DropDownListSection = loadable(() =>import("@common/CommonSections/DropDownListSection/DropDownListSection"));

const OffersSection = () => {
  const { isMobile } = useWindowDimensions();

  return (
    <OfferSectionContainer>
      <DropDownListSection
        sectionItems={IMPRESSIT_OFFER_ITEMS}
        isMobile={isMobile}
        heading={IMPRESSIT_OFFERS_HEADING}
        headingMargin="2.5rem"
        itemsMargin="3.125rem"
      />
    </OfferSectionContainer>
  )
};

export default OffersSection;
